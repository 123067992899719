<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col
            class="mb-2"
            cols="12"
          >
            <h5 class="mb-0">
              Basic Information
            </h5>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Program Type"
              label-for="programType"
            >
              <v-select
                id="programType"
                v-model="program.programType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['Pre-incubation Program', 'Incubation Program', 'Accelerator Program']"
                label="Program Type"
                placeholder="Program Type"
                :disabled="true"
              />

            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Program Name"
              label-for="programName"
            >
              <b-form-input
                id="programName"
                v-model="program.programName"
                :disabled="true"
                placeholder="Program Name"
                type="text"
              />

            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Startup Capacity"
              label-for="programCapacity"
            >
              <b-form-input
                id="programCapacity"
                v-model="program.programCapacity"
                :disabled="true"
                placeholder="Number of participants"
                type="number"
              />

            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Duration"
              label-for="duration"
            >
              <b-form-input
                id="duration"
                v-model="program.programDuration"
                :disabled="true"
                placeholder="in Months"
                type="number"
              />

            </b-form-group>

            <b-form-group
              label="Ideal Startup Lifecycle Stage"
              label-for="lifeCycleStage"
            >
              <v-select
                id="lifeCycleStage"
                v-model="program.lifeCycleStage"
                :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                append-to-body
                multiple
                :disabled="true"
                placeholder="Select one or multiple options"
              />

            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Participant Industry/Sector"
              label-for="industry"
            >
              <v-select
                id="industry"
                v-model="program.industry"
                :options="['Technology', 'Agriculture']"
                append-to-body
                multiple
                :disabled="true"
                placeholder="Select one or multiple options"
              />

            </b-form-group>
          </b-col>
          <b-col>
            <hr>
          </b-col>
          <b-col md="12">
            <b-row
              v-for="index in program.criteria.length"
              :key="index"
            >
              <b-col>
                <b-form-group
                  :label="`Criteria ${index}`"
                  :label-for="`Criteria-${index}`"
                >
                  <b-form-input
                    :id="`Criteria-${index}`"
                    v-model="program.criteria[index-1]"
                    :disabled="true"
                    placeholder="Criterion"
                    type="text"
                  />

                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <hr>
          </b-col>
          <b-col md="12">
            <b-row
              v-for="index in program.deliverables.length"
              :key="index"
            >
              <b-col>
                <b-form-group
                  :label="`Deliverable ${index}`"
                  :label-for="`Deliverable-${index}`"
                >
                  <b-form-input
                    :id="`Deliverable-${index}`"
                    v-model="program.deliverables[index - 1]"
                    :disabled="true"
                    placeholder="Deliverable"
                    type="text"
                  />

                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <hr>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Fundraising Assistance"
            >
              <div class="d-flex">
                <b-form-checkbox-group
                  v-model="program.fundraising"
                  :disabled="true"
                  class="d-inline"
                  disabled-field="disabled"
                  :options="[{ text: 'Demo Day', value: 'demoDay', disabled: program.fundraisingDisable}, { text: 'Personal Pitching', value: 'personalPitching', disabled: program.fundraisingDisable }, { text: 'Liaisoning', value: 'Liaisoning', disabled: program.fundraisingDisable }
                  ]"
                />
                <b-form-checkbox
                  v-model="program.fundraisingDisable"
                  :disabled="true"
                  value="true"
                  class="custom-control-danger"
                  @change="program.fundraisingDisable ? fundraising = [] : '' "
                >
                  None
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!program.fundraisingDisable"
            md="6"
          >
            <b-form-group
              label="Ticket Size Lower limit"
              label-for="fundRaisingSize_l"
            >
              <b-form-input
                id="fundRaisingSize_l"
                v-model="program.fundRaisingSize[0]"
                :disabled="true"
                placeholder="Lower Limit"
                type="number"
              />

            </b-form-group>
          </b-col>
          <b-col
            v-if="!program.fundraisingDisable"
            md="6"
          >
            <b-form-group
              label="Ticket Size Upper limit"
              label-for="fundRaisingSize_u"
            >
              <b-form-input
                id="fundRaisingSize_u"
                v-model="program.fundRaisingSize[1]"
                :disabled="true"
                placeholder="Upper Limit"
                type="number"
              />

            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Inhouse Funds"
              label-for="inhouseFunds"
            >
              <b-form-input
                id="inhouseFunds"
                v-model="program.inhouseFunds"
                :disabled="true"
                placeholder="Total Inhouse Funds"
                type="number"
              />

            </b-form-group>
          </b-col>
          <b-col
            v-if="program.inhouseFunds > 0"
            md="4"
          >
            <b-form-group
              label="Inhouse Funds Lower limit"
              label-for="inhouseFundsSize_l"
            >
              <b-form-input
                id="inhouseFundsSize_l"
                v-model="program.inhouseFundsSize[0]"
                :disabled="true"
                placeholder="Lower Limit"
                type="number"
              />

            </b-form-group>

            <b-form-group
              label="Inhouse Funds Upper limit"
              label-for="inhouseFundsSize_u"
            >
              <b-form-input
                id="inhouseFundsSize_u"
                v-model="program.inhouseFundsSize[1]"
                :disabled="true"
                placeholder="Upper Limit"
                type="number"
              />

            </b-form-group>
          </b-col>
           <b-col
            lg="12"
            class="text-right"
          >
            <b-button variant="primary" @click="$router.back()">
               <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              <span>Back</span>
            </b-button>
           </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BCard, BFormGroup, BFormInput, BRow, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
// import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
// import FormBuilder from './components/FormBuilder.vue'
// import ObjectiveForm from './components/ObjectiveForm.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import AddProgram from './components/gql/AddProgram.gql'
// import AddCriterion from './components/gql/AddCriterion.gql'

export default {
  components: {
    // FormWizard,
    // TabContent,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    // FormBuilder,
    // ObjectiveForm,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      programValues: '',
      inputVariable: {
        criteriaTable: [],
        deliverableTable: [],
        roundsTable: [],
        phaseTable: [],
        sectionTest: [],
      },
      program: {
        programName: '',
        programId: '',
        programType: '',
        programDuration: '',
        programCapacity: '',
        lifeCycleStage: [],
        industry: '',
        criteria: [
          '',
        ],
        deliverables: [
          '',
        ],
        fundraisingDisable: false,
        fundraising: [],
        fundRaisingSize: [],
        inhouseFunds: '',
        inhouseFundsSize: [],
        rounds: [
          {
            title: '',
            applicationForm: '', // Refer to TrenchesFormBuilder.vue for structure
            reviewForm: '',
          },
        ],
        phases: [
          {
            title: '',
            duration: '',
            objectiveForm: '', // Refer to ObjectiveForm.vue for structure
          },
        ],
      },
      required,
      email,
    }
  },
  watch: {
    programValues(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setProgramModel()
      }
    },
    phaseValues(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setPhaseModel()
      }
    },
  },
  methods: {
    formSubmitted() {
      if (!this.$route.params.id) {
        this.addProgram()
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    addProgram() {
      this.setVariables()
      this.$apollo.mutate({
      // Query
        mutation: gql`mutation (
            $title: String!,
            $type: String!,
            $industry: String!,
            $ideal_lifecycle_stage: String!,
            $fund_raising_assistance: String!,
            $ticket_size_of_fund_raised: String!,
            $capacity: Int!,
            $inhouse_funds: String!,
            $inhouse_ticket_size: String!,
            $criteriaInput: [programs_shortlistingcriteriontable_insert_input!]!,
            $deliverableInput: [programs_deliverablelisttable_insert_input!]!,
            $roundInput: [programs_applicationformstable_insert_input!]!,
        ) {
          insert_programs_basicinfo(objects: [{
            title: $title,
            type: $type,
            status: "on",
            industry: $industry,
            ideal_lifecycle_stage: $ideal_lifecycle_stage,
            fund_raising_assistance: $fund_raising_assistance,
            ticket_size_of_fund_raised: $ticket_size_of_fund_raised,
            capacity: $capacity,
            inhouse_funds: $inhouse_funds,
            inhouse_ticket_size: $inhouse_ticket_size
            programs_shortlistingcriteriontables: {
              data: $criteriaInput
            }
            programs_deliverablelisttables: {
              data: $deliverableInput
            }
            programs_applicationformstables: {
              data: $roundInput
            }
            }])
            {
            returning{
              id
              title
            }
          }
        }`,
        variables: {
          title: this.program.programName,
          type: this.program.programType,
          industry: this.program.industry.toString(),
          ideal_lifecycle_stage: this.program.lifeCycleStage.toString(),
          fund_raising_assistance: this.program.fundraising.toString(),
          ticket_size_of_fund_raised: this.program.fundRaisingSize.toString(),
          capacity: parseInt(this.program.programCapacity, 10),
          inhouse_funds: this.program.inhouseFunds,
          inhouse_ticket_size: this.program.inhouseFundsSize.toString(),
          criteriaInput: this.program.criteria,
          deliverableInput: this.program.deliverables,
          roundInput: this.inputVariable.roundsTable,
        },
      }).then(data => {
      // Result
        this.programId = data.data.insert_programs_basicinfo.returning[0].id
        this.addPhase()
      })
      this.resetValues()
      return this.error
    },
    addPhase() {
      for (let i = 0; i < this.program.phases.length; i += 1) {
        this.inputVariable.phaseTable.push({ program_id: parseInt(this.programId, 10), operations_type: 'Phase', title: this.program.phases[i].title })
      }
      this.$apollo.mutate({
      // Query
        mutation: gql`mutation (
            $phaseInput: [programs_operationstable_insert_input!]!
        ) {
          insert_programs_operationstable(objects: $phaseInput)
            {
              returning{
                id
              }
            }
        }`,
        variables: {
          phaseInput: this.inputVariable.phaseTable,
        },
      })
    },
    setVariables() {
      for (let i = 0; i < this.program.rounds.length; i += 1) {
        this.inputVariable.roundsTable.push({
          title: this.program.rounds[i].title,
          round_number: i + 1,
          lprograms_applicationsquestionstables: { data: [] },
          programs_applicationreviewquestionnairetables: { data: [] },
        })
        for (let j = 0; j < this.program.rounds[i].applicationForm.length; j += 1) {
          for (let k = 0; k < this.program.rounds[i].applicationForm[j].items.length; k += 1) {
            this.inputVariable.roundsTable[i].programs_applicationsquestionstables.data.push(
              {
                question: this.program.rounds[i].applicationForm[j].items[k].question,
                section: this.program.rounds[i].applicationForm[j].title,
                question_number: k + 1,
                answer_type: this.program.rounds[i].applicationForm[j].items[k].dataType,
              },
            )
          }
        }
        for (let j = 0; j < this.program.rounds[i].reviewForm.length; j += 1) {
          for (let k = 0; k < this.program.rounds[i].reviewForm[j].items.length; k += 1) {
            this.inputVariable.roundsTable[i].programs_applicationreviewquestionnairetables.data.push(
              {
                question: this.program.rounds[i].reviewForm[j].items[k].question,
                section: this.program.rounds[i].reviewForm[j].title,
                question_number: k + 1,
                answer_type: this.program.rounds[i].reviewForm[j].items[k].dataType,
              },
            )
          }
        }
      }
    },
    resetValues() {
      this.inputVariable.roundsTable = []
    },
    setProgramModel() {
      if (this.$route.params.id) {
        this.program.programName = this.programValues.title
        this.program.programType = this.programValues.type
        this.program.programCapacity = this.programValues.capacity
        this.program.lifeCycleStage = (this.programValues.ideal_lifecycle_stage) ? this.programValues.ideal_lifecycle_stage.split(',') : []
        this.program.industry = (this.programValues.industry) ? this.programValues.industry.split(',') : ''
        for (let i = 0; i < this.programValues.programs_shortlistingcriteriontables.length; i += 1) {
          this.program.criteria.push(this.programValues.programs_shortlistingcriteriontables[i].criteria)
        }
        for (let i = 0; i < this.programValues.programs_deliverablelisttables.length; i += 1) {
          this.program.deliverables.push(this.programValues.programs_deliverablelisttables[i].deliverable)
        }
        this.program.fundraising = (this.programValues.fund_raising_assistance) ? this.programValues.fund_raising_assistance.split(',') : []
        this.program.fundRaisingSize = (this.programValues.ticket_size_of_fund_raised) ? this.programValues.ticket_size_of_fund_raised.split(',') : []
        this.program.inhouseFunds = this.programValues.inhouse_funds
        this.program.inhouseFundsSize = (this.programValues.inhouse_ticket_size) ? this.programValues.inhouse_ticket_size.split(',') : []
        for (let i = 0; i < this.programValues.programs_applicationformstables.length; i += 1) {
          this.program.rounds[i].title = this.programValues.programs_applicationformstables[i].title
          if (i !== this.programValues.programs_applicationformstables.length - 1) {
            this.program.rounds.push(
              {
                title: '',
                applicationForm: '',
                reviewForm: '',
              },
            )
          }
          this.inputVariable.sectionTest = [...new Set(this.programValues.programs_applicationformstables[i].programs_applicationsquestionstables.map(x => x.section))]
        }
      }
    },
    setPhaseModel() {
      if (this.$route.params.id) {
        for (let i = 0; i < this.phaseValues.length; i += 1) {
          this.program.phases[i].title = this.phaseValues[i].title
          if (i !== this.phaseValues.length - 1) {
            this.program.phases.push(
              {
                title: '',
                duration: '',
                objectiveForm: '', // Refer to ObjectiveForm.vue for structure
              },
            )
          }
        }
      }
    },
  },
  apollo: {
    programValues: {
      query() {
        return gql`
        {
          programs_basicinfo(where: {id: {_eq: ${this.$route.params.id}}}) {
            id,
            title,
            type,
            capacity,
            ideal_lifecycle_stage,
            industry,
            programs_shortlistingcriteriontables{
              criteria
            },
            programs_deliverablelisttables{
              deliverable
            }
            fund_raising_assistance,
            ticket_size_of_fund_raised,
            inhouse_funds,
            inhouse_ticket_size,
            programs_applicationformstables {
              round_number
              title
              programs_applicationsquestionstables {
                answer_type
                question
                section
              }
              programs_applicationreviewquestionnairetables {
                answer_type
                question
                section
              }
            }
          }
        }`
      },
      update: data => data.programs_basicinfo[0],
    },
    phaseValues: {
      query() {
        return gql`
        {
          programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}}) {
            title
          }
        }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
